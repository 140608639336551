.backgrid {
  td.renderable,
  th.renderable,
  th.sortable {
    text-align: left;
    cursor: default;
  }

  td,
  th {
    &.visible {
      display: table-cell;
    }
    &.no-padd {
      padding: 0;
    }
  }
}

.backgrid {
  th,
  td {
    @media (max-width: 800px) {
      &:nth-child(5) {
        display: none;
      }
    }
    @media (max-width: 600px) {
      &:nth-child(7) {
        display: none;
      }
    }
    @media (max-width: 500px) {
      &:nth-child(8) {
        display: none;
      }
    }
    @media (max-width: 500px) {
      &:nth-child(3) {
        display: none;
      }
    }
  }
}

// Backgrid.ColumnManager Override
div.columnmanager-visibilitycontrol > button.dropdown-button {
  border: 0;
  background: transparent;
  color: #333;
  outline: none !important;
  text-shadow: none;
  font-weight: $f-normal;
  box-shadow: none;

  &:hover {
    background: none;
    color: #000;
  }

  &:active {
    background: none;
    text-shadow: none;
    color: #000;
  }
}

div.columnmanager-dropdown-container {
  min-width: 220px;
  max-height: 26.4px * 10 + 2px; // 10 rows
  overflow: auto;
  box-shadow:
    0px 2px 5px rgba(0, 0, 0, 0.1),
    0px 5px 15px rgba(0, 0, 0, 0.1);
  border-radius: 0;
  z-index: 1030;
}

div.columnmanager-dropdown-container {
  > li {
    padding: 5px 10px;
    display: flex;
    align-items: center;
    gap: 0.5rem;

    > span.column-label {
      width: auto;
      max-width: 90%;
    }
    > span.indicator {
      width: auto;

      &:before {
        content: '✓';
        visibility: hidden;
      }
    }

    &.visible {
      > span.indicator:before {
        visibility: visible;
        color: #89ba00;
      }
    }
  }
}

.filters-panel {
  background: white;

  padding: 1rem 1.5rem;
  margin: 2rem 0;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  border-radius: 5px;

  .filters.row {
    gap: 0.5rem;
  }

  &:empty {
    display: none;
  }

  .backgrid-filter {
    &.form-search {
      width: auto;
      height: auto;
      margin: 0;
    }

    &.flatpickr {
    }

    .form-control {
      padding-left: 0;
      padding-right: 0;
    }

    input[type='search'],
    input[type='number'] {
      position: relative;
      width: 100%;
      height: 34px;
      box-sizing: border-box;
      padding-left: 0px;
      border-radius: 0px;
      border: none;
      border-bottom: 1px solid $secondary;
      box-shadow: none;
      outline: none;
    }

    label {
      position: relative;
      display: flex;
      flex-direction: column;

      &:has(> input[type='checkbox'], > input[type='radio']) {
        flex-direction: row;
        gap: 0.5rem;
        align-items: center;
      }

      .input-wrapper {
        position: relative;
      }

      .after-input-label {
        display: flex;
        white-space: nowrap;
        align-items: center;
        gap: 0.5rem;
      }

      // the actual label text display
      > span {
        white-space: nowrap;
        background-color: white;
        color: $brand-color-green;
        cursor: pointer;
      }

      .clear {
        top: auto;
        right: 0;
        width: auto;
        height: auto;
        cursor: pointer;
        background-color: white;
      }
      a.clear {
        bottom: 0.5rem;
      }
      button.clear {
        bottom: 0.25rem;
      }

      &:has(input[type='number']) {
        button.clear {
          right: 1.25rem; // so both arrows and clear button are visible
        }
      }
    }
  }
}

// Backgrid Filters Override
.grid-page {
  position: relative;

  .column-filter {
    position: absolute;
    right: 0;
    top: 0;
  }
}

.column-filter {
  .column-visibility {
    border: 1px solid #ddd;
    padding: 5px;
    border-radius: 4px;
    background: white;

    display: flex;
    align-items: center;

    &:empty {
      display: none;
    }

    .columnmanager-visibilitycontrol {
      width: auto;
    }
  }
}

// we don't want to include backgrid-paginator styles
// because it's use bunch of css hacks which throws off our css minifier
.backgrid-paginator {
  ul {
    list-style: none;
    padding: 0;
    margin: 5px 0;

    display: flex;
    justify-content: center;
    align-items: center;

    li {
      a,
      a:hover {
        text-decoration: none;
        color: inherit;
        cursor: default;

        display: inline-block;
        text-align: center;
        width: 30px;
        height: 30px;
        line-height: 30px;
      }

      &.active {
        a {
          color: $brand-color;
        }
      }

      &.disabled {
        opacity: 0.5;
      }

      &:not(.disabled) {
        a {
          cursor: pointer;

          &:hover {
            color: $brand-color;
          }
        }
      }
    }
  }
}

.grid {
  overflow: auto;
  width: 100%;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  border-radius: 5px;

  .select2-selection__rendered {
    padding-left: 10px !important;
  }

  table {
    width: 100%;

    th {
      font-family: Roboto, Arial, sans-serif;
      padding: 6px 10px;
      font-weight: $f-thin;
      color: #444443;
      border-left: 1px solid #ddd;
      background-color: #edeff0;

      &:first-child {
        border-left: 0;
      }

      &:last-child {
        border-right: 0;
      }
    }

    tr {
      &:last-child td {
        border-bottom: 0;
      }

      td {
        font-family: Roboto, Arial, sans-serif;
        font-size: 14px;
        font-weight: $f-normal;
        color: dimgray;
        padding: 4px 10px;
        vertical-align: middle;
        border-bottom: 1px solid #f9f9f9;
        border-left: 1px solid #f9f9f9;

        &:first-child {
          border-left: 0;
        }
      }
    }

    &.backgrid-hoverable {
      tr:hover > td {
        background-color: darken($expense-list-item-hover, 1%);
      }
    }
  }
}

.total-amount {
  margin-left: 10px;
  font-weight: $f-normal;
}
.total-tax-amount {
  font-weight: $f-normal;
}
.total-emissions {
  font-weight: $f-normal;
  margin-left: 0 !important;
}
