@import '_variables'; // should be included before bootstrap css
@import 'bootstrap/scss/bootstrap';
@import '_mixins';
@import 'dropzone/dist/dropzone';
@import 'custom'; // TODO: this needs to go _after_ application.css
@import 'backgrid/lib/backgrid';
@import 'backgrid-columnmanager/lib/Backgrid.ColumnManager';
@import 'backgrid-filter/backgrid-filter';
@import 'backgrid-select-all/backgrid-select-all';
@import 'select2/dist/css/select2';
@import 'select2-bootstrap-theme/dist/select2-bootstrap';
@import 'backgrid-select2-cell/backgrid-select2-cell';
@import 'backgrid-select-all/backgrid-select-all';
@import 'flatpickr/dist/flatpickr';

input {
  // hide autofill contact button in safari, needs :: to work
  &::-webkit-contacts-auto-fill-button {
    visibility: hidden;
  }
  // delay setting autofilled input background for an hour
  &:autofill {
    transition: background-color 0s 3600s;
  }
}

form {
  margin: 0;
}

.btn-group {
  // ignore whitespace btw buttons in the html
  display: inline-flex;
}

body,
html {
  font-family: $font-family-sans-serif;
  font-weight: $f-thin;
  font-size: 14px;
  background-color: $body-bg-color;
}

.navbar {
  background: $brand-color-green;

  .company-name {
    font-size: 18px;
    color: white;
    font-weight: $f-bold;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .nav-link {
    --bs-nav-link-color: white;
    --bs-nav-link-hover-color: white;
    --bs-btn-active-color: white;

    display: block;
    line-height: 35px;
    min-width: 35px; // square help and user menu icon-buttons
    text-align: center;

    [class*='icon-'] {
      width: 18px;
    }
  }

  .user-menu-wrapper {
    position: relative;

    .user-menu {
      left: auto;
      right: 0;
      top: calc(100% + 10px);
    }
  }
}

//SECTIONS
.section-header {
  background: transparent;
  cursor: default;

  h1 {
    font-family: Roboto, Arial, sans-serif;

    color: $text-gray;
    font-weight: $f-normal;
    font-size: 14px;
    line-height: 1.2em;
  }

  a:not(.connected-budget-link) {
    color: $text-gray;
  }
}

.section-body-box {
  background-color: transparent;
  border: none;
  border-radius: 0;

  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  @media (max-width: 420px) {
    background-color: #f9f9f9;
    border: 0px;
  }
}

.section-body {
  .table {
    color: #000 !important;
    margin-bottom: 0;
    background-color: #fff;

    td,
    th {
      font-weight: $f-normal;
    }

    p {
      padding: 0;

      &.date {
        color: $brand-color !important;
      }
    }
    p,
    small {
      color: dimgray;
    }

    h1 {
      font-size: 14px;
      margin-top: 0;
      color: $text-gray;
      margin-bottom: 5px;
      font-weight: $f-light-bold;
    }

    &:hover,
    &.active {
      background: #f1f1f1;
    }
    .summary {
      border-top: 1px solid #d3d2d3;
      border-bottom: 1px solid #d3d2d3;
    }
    .summary > tbody > tr > td {
      background: transparent !important;
      border: 0;
      font-family: Roboto, Arial, sans-serif;
      color: #ababab;
      font-weight: $f-normal;
      padding: 10px;
    }
  }
  .header {
    border: 0;
    p {
      display: inline-block;
      margin: 0;
      font-family: Roboto, Arial, sans-serif;
      color: dimgray;
      font-size: 13px;
      padding: 0;
    }
  }
  .form-group {
    border: 0;
    padding-bottom: 5px;
  }
}

.header-bg {
  border: 1px solid #edeff0;
  border-radius: 2px;
  border-bottom: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.approval-cycle {
  @include receipt-form-header;
}

.batch-sending-failure {
  @include receipt-form-header;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  > i {
    align-self: flex-start;
  }
}

.paid-on {
  @include receipt-form-header;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.modal {
  form {
    margin-top: 10px;
  }
  .modal-title {
    color: #1374c7;
  }
  .modal-subtitle {
    color: #1374c7;
    font-size: 18px;
    border-bottom: 1px solid #ddd;
    padding: 40px 0 20px 14px;
    margin: 0 0 30px 0;
  }
}

.modal-dialog .button-panel {
  padding: 10px;
}

// Flexible videos
.video-container {
  position: relative;
  padding-bottom: 56.25%;
  margin-bottom: 20px;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

//TABLES
table.summary {
  td:last-child {
    text-align: right;
  }
}

table {
  background-color: white !important;
}
//SUPPORTIVE
.highlight {
  color: #55b973;
}

.bold {
  font-weight: $f-bold;
}

.no-padd {
  padding: 0 1px !important;
}

.email-profile {
  padding: 15px;
}

.email-box {
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  background-color: #fff;
  padding: 20px;
  text-align: center;
  border-radius: 5px;
  margin: 0 0 10px 0;
}

.toggle-arrow {
  position: absolute;
  top: 10px;
  left: 10px;
}

.white-bg {
  background: #f9f9f9;
}

/* layout start */

html,
body {
  overflow: hidden;
  height: 100%;
}

$zindex-navbar: $zindex-fixed + 3;
$zindex-notifications: $zindex-navbar + 1;

body {
  // top inset shadow
  &:before {
    content: '';
    box-shadow: inset 0 8px 10px -8px rgba(black, 0.55);
    z-index: $zindex-notifications + 1;
    position: absolute;
    left: 0;
    right: 0;
    height: $header-height;
    pointer-events: none;
  }
}

.notifications {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: $zindex-notifications;

  display: flex;
  flex-direction: column;
  align-items: flex-end; // so close btns line up
  gap: 1rem;

  .alert {
    cursor: default;
    margin: 0;

    width: fit-content;

    $shadow-color: color-mix(in srgb, var(--bs-alert-color), transparent 45%);
    box-shadow: $shadow-color 0 0 8px 0;

    &:first-child {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-top: 0;
    }
  }
}

$main-menu-icon-height: 22px;
.dropdown-menu {
  .transaction-icon {
    width: $main-menu-icon-height;
    line-height: $main-menu-icon-height;

    background-color: $expense-icon-gray;
  }

  .dropdown-item {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    white-space: nowrap;
  }

  // main menu presumably
  .dropdown-item:hover .transaction-icon {
    background-color: black;
  }
}

.user-menu,
.create-menu {
  // covers the gap btw button and menu
  &:before {
    content: '';
    background: transparent;
    height: 16px;
    display: block;
    transform: translateY(-16px);
    position: absolute;
    width: 100%;
  }
}

// 'new expense'
.create-menu {
  .dropdown-item:hover .transaction-icon {
    background-color: $brand-color-green;
  }
}
// 'user menu'
.user-menu {
  .dropdown-item:hover {
    &:before {
      background-color: black;
    }
  }
}

// main menu icons
// outside of nav-menu because we want them in the user menu dropdown as well
[class^='mm-icon-'],
[class*=' mm-icon-'] {
  &:before {
    width: $main-menu-icon-height;
  }
}

// main menu
#nav-menu {
  font-size: 16px;
  font-weight: $f-light-bold;
  color: $menu-text-color;

  &.show {
    display: flex !important;
  }

  flex-direction: column;
  gap: 5px;
  padding: 10px;

  .dropdown-header {
    font-size: 15px;
    color: rgba($menu-text-color, 50%);
    margin: 10px 0 5px;
    padding: 0;
  }

  .dropdown-item {
    // drop boostrap styles for menu items
    all: unset;

    padding: 10px;
    padding-right: 15px; // so counters on budgets are aligned w/ counters on expensess
    border-radius: 40px;
    cursor: pointer;

    display: flex;
    gap: 15px;
    align-items: center;
    white-space: nowrap;

    transition: background-color 0.2s;
    &:before {
      transition: background-color 0.2s;
    }

    &:hover {
      background-color: rgba($menu-hover-bg, 15%);

      &:before {
        background-color: black;
      }
    }

    .caption {
      flex-grow: 1;
    }
  }

  ul.menu_group {
    margin: 0;
    padding: 0;
    list-style: none;

    position: relative;

    &:before {
      content: '';
      border-left: 1px solid rgba($expense-icon-gray, 40%);
      position: absolute;
      top: 48px; // first row height
      left: 22px; // horiz center of the icon
      bottom: 0;
    }

    li:not(.menu_group-header) {
      margin-left: 48px; // match group header text indent
      margin-right: 15px;

      a {
        font-weight: $f-normal;
        padding: 8px 0;
        border-radius: 0;

        display: flex;
        // so long sub-items have a chance to wrap to two rows
        // "Scheduled to be sent to your bookkeeper" needs this
        white-space: normal;

        &:hover {
          background-color: transparent;

          .caption {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .dropdown-divider {
    --bs-dropdown-divider-bg: #{$expense-icon-gray};
    margin: 5px 0;
  }

  .send-receipts-link {
    &:before {
      background-color: $brand-color-green;
    }
    color: $brand-color-green;

    &:hover {
      &:before {
        background-color: $brand-color-green;
      }
    }
  }
}

.navbar {
  max-height: 100%;
  grid-area: navbar;

  // both top bar dropdowns (main menu and companies list)
  // need to be scrollable vertically
  // and need to be on top of everything else incl. flip panel
  .scrollable-menu {
    overflow-y: auto;
  }

  .menu-counter {
    // we want consistent looking small numbers on all platforms,
    // so no custom fonts
    font-family: arial, sans-serif;
    font-weight: bold;

    color: $brand-color-green;
    background-color: rgba($brand-color-green, 30%);
    border-radius: 3px;
    font-size: 12px;
    line-height: 1;
    display: inline-flex;
    justify-content: center;
    min-width: 1rem;
    padding: 3px 5px;

    &.is-actionable {
      color: white;
      background-color: $brand-color-green;
    }
  }

  // tooltip wrappes has to have same position as menu-counter child
  [data-bs-toggle='tooltip']:has(.menu-counter) {
    display: inline-flex;
  }

  #nav-menu {
    // always take all available height
    min-height: calc(100dvh - #{$header-height});
    cursor: default;

    // show menu always on extra wide screens
    @include media-breakpoint-up($name: 'xxl') {
      position: absolute;
      top: $header-height;
      left: 0;
      width: $sidebar-width;
      border-radius: 0;
      box-shadow: none !important;

      .dropdown-item {
        white-space: normal;
      }
    }
  }

  #nav-menu-toggle {
    position: relative;

    gap: 4px;
    flex-direction: column;
    display: flex;

    &.show {
      display: flex !important;
    }

    .menu-counter {
      position: absolute;
      right: 2px;
      top: 0;
      color: $brand-color-green;
      background-color: white;
      font-size: 11px;
      font-weight: $f-bold;
      padding: 2px 2px 1px;
    }

    .burger-bar {
      display: block;
      width: 22px;
      height: 2px;
      background: white;
      border-radius: 0;
    }
  }
}

#page {
  display: grid;
  grid-template-rows: $header-height auto;

  grid-template-areas:
    'navbar'
    'page-content';

  transition: padding-left 0.4s ease;

  // always expanded main menu
  @include media-breakpoint-up($name: 'xxl') {
    padding-left: $sidebar-width;
  }
}

.page-buttons {
  --page-buttons-left-space: 0rem; // important to have unit here

  @include media-breakpoint-up($name: 'xxl') {
    --page-buttons-left-space: #{$sidebar-width};
  }
}

#page-content {
  grid-area: page-content;

  &:empty {
    display: none;
  }

  .three-column-layout,
  #two-column-container,
  .one-column-layout {
    display: grid;
  }
}

.three-column-layout {
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas: 'col1 col2 col3';

  &.has-wide_list {
    grid-template-columns: 1fr 0 0;
  }

  &.has-wide_form {
    grid-template-columns: 1fr 2fr 0;
  }

  #list-container {
    grid-area: col1;
  }
  #form-container {
    grid-area: col2;
  }
  #image-container {
    grid-area: col3;
  }

  @media (max-width: $three_col-breakpoint) {
    grid-template-columns: 1fr;
    grid-template-areas: 'col1';

    #list-container,
    #form-container,
    #image-container {
      grid-area: col1;
    }

    &.has-wide_form {
      grid-template-columns: 1fr;

      #list-container {
        display: none;
      }
      #form-container {
        // override columns hiding machinery
        // app/assets/stylesheets/responsive.scss:46
        display: block;
      }
    }
  }
}

// TODO: rename this to 'scroll parent' and use one across all layouts
.two-column-layout {
  overflow: auto;

  // this is actual layoyt
  #two-column-container {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: 'col1 col2';

    @media (max-width: $two_col-breakpoint) {
      grid-template-columns: 1fr;
      grid-template-areas:
        'col2'
        'col1';
    }
  }

  #first-column {
    grid-area: col1;
  }
  #second-column {
    grid-area: col2;
  }
}

.one-column-layout {
  grid-template-columns: 1fr;
  grid-template-areas: 'col1';

  #one-column-container {
    grid-area: col1;
  }
}

#list-container,
#form-container,
#image-container,
#one-column-container {
  // otherwise children overflow-y sometimes doesn't work
  // eg 'new receipt' produces ghost scrolling at the bottom
  position: relative;
  overflow-y: auto;

  &.is-hidden {
    display: none;
  }
}

// reserve space for bottom fixed button row,
// use same horiz padding as one-column-container
#one-column-container {
  padding-bottom: $actions-bar-height;
  padding-left: $one-col-padding;
  padding-right: $one-col-padding;
}

.navbar {
  &.fixed-top {
    // we want menus to be on top of bottom-fixed button bars that are later in DOM
    // and on top of flip panel
    z-index: $zindex-navbar;
  }
}

// by making each column parent have 100% height
@mixin drill-layout-height {
  #page {
    height: 100%;

    #page-content {
      height: 100%;

      > div {
        height: 100%;
      }
    }
  }

  #list-container,
  #form-container,
  #image-container,
  #one-column-container {
    height: 100%;
  }

  .navbar {
    .scrollable-menu {
      max-height: calc(100vh - #{1.5 * $header-height});
    }
  }
}

@supports not (height: 1dvh) {
  @include drill-layout-height;
}

// use dynamic viewport size units for browsers that support it
@supports (height: 1dvh) {
  .one-column-layout,
  .two-column-layout,
  .three-column-layout {
    height: calc(100dvh - #{$header-height});
  }

  .navbar {
    .scrollable-menu {
      max-height: calc(100dvh - #{$header-height});
    }
  }

  // with an exception of safari 15 which has a bug with dynamic units
  @include safari15 {
    @include drill-layout-height;
  }
}

#policy-nav_link {
  transition: transform 0.11s ease;

  &.is-active_route {
    transform: scale(1.44);
  }
}

.flip_panel {
  $panel-width: 44%;
  $z-index: $zindex-fixed + 1; // cover fixed-bottom buttons

  position: fixed;
  right: 0;
  top: $header-height;
  height: calc(100dvh - #{$header-height});
  overflow-x: visible;
  overflow-y: auto;
  width: $panel-width;
  padding: 20px 30px;

  display: none;
  z-index: $z-index;
  background-color: white;
  box-shadow: rgba(black, 22%) -5px 0 12px -5px;

  &.is-shown {
    display: block;
  }

  // cover existing content
  &:after {
    content: '';
    z-index: $z-index + 1;
    background-color: rgba(white, 33%);

    position: fixed;
    top: $header-height;
    left: 0;
    right: $panel-width;
    bottom: 0;
  }

  .flip_panel-close {
    font-style: normal;
    color: dimgray;
    box-shadow: inherit;
    font-size: 22px;
    text-align: center;

    background-color: white;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;

    position: fixed;
    width: 40px;
    height: 40px;
    line-height: 42px;
    top: $header-height;
    right: $panel-width;
    z-index: $z-index + 2;

    cursor: pointer;
    user-select: none;
  }

  @media (max-width: $three_col-breakpoint) {
    width: 100%;

    &:after {
      display: none;
    }

    .flip_panel-close {
      right: 0;
      border-radius: 0;
      box-shadow: none;
    }
  }
}

/* layout end */

.costs-container {
  display: inline-block;
  width: 100%;
}

// Signup page

.authform {
  margin: 0 auto;
  padding: 20px;
  background: white;
  border-radius: 5px;
  border: none;
  color: #333;
  cursor: default;
  max-width: 450px;
  box-shadow:
    0px 2px 3px rgba(0, 0, 0, 0.2),
    0px 8px 15px rgba(0, 0, 0, 0.1);

  a {
    font-family: Roboto, Arial, sans-serif;
    font-weight: $f-normal;
    color: $brand-color;
    display: inline-block;
  }
  p {
    font-family: Roboto, Arial, sans-serif;
    color: dimgray;
  }
  h2,
  h3 {
    margin: 20px 0;
    font-family: Roboto, Arial, sans-serif;
    font-weight: $f-light-bold;
    font-size: 15px;
    line-height: 1.25em;
    text-align: center;
    color: $text-gray !important;
    span {
      color: $text-gray !important;
    }
  }
  h4 {
    width: 100%;
    text-align: center;
    border-bottom: 2px dashed lightgrey;
    line-height: 0.1em;
    margin: 30px 0;
    font-size: 13px;
    font-weight: $f-normal;
  }
  h4 span {
    background: #fff;
    padding: 0 10px;
  }
  form {
    margin-bottom: 10px;
  }
  abbr[title] {
    border: 0;
    text-decoration: none;
  }
  .form-control {
    font-family: Roboto, Arial, sans-serif;
    font-weight: $f-normal;
    color: dimgray;
  }
  label {
    font-family: Roboto, Arial, sans-serif;
    font-weight: $f-normal;
    font-size: 13px;
    color: $brand-color-green;
  }
  .submit-btn {
    width: 100%;
    margin: 0 auto;
    display: block;
    padding: 10px 15px;
    border: none;
    text-shadow: none;
    font-family: Roboto, Arial, sans-serif;
    font-weight: $f-thin;
    letter-spacing: 0.032em;
    font-size: 15px;
    border-radius: 4px;
    box-shadow: none;
    color: #fff;

    background: #4daf88;
    &:hover,
    &:focus,
    &:active {
      background: #219b6a;
    }
    &:disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }
  .submit-btn,
  .signin-link {
    margin-top: 1rem;
  }
  #error_explanation {
    padding: 10px 0;
    h2 {
      font-family: Roboto, Arial, sans-serif;
      font-weight: $f-thin;
      font-size: 14px;
      line-height: 1.2em;
      color: #e85062;
      padding: 10px 0;
      margin: 0;
    }

    ul li {
      font-family: Roboto, Arial, sans-serif;
      font-weight: $f-thin;
      color: #e85062;
      font-size: 13px;
    }
  }
}

.page-header {
  @include page-header;

  padding: 5px 0 5px 0;
  margin: 15px 0 10px 0;
  border-bottom: none;
  cursor: default;

  span {
    font-size: 15px;
    color: #55b973;
    margin: 0 0 0 6px;

    &.timestamp {
      color: $text-gray;
      font-weight: $f-normal;
      font-style: italic;
    }
  }
}

.green-header {
  margin-top: 10px;
  border-radius: 5px;

  display: flex;
  gap: 1rem;
  padding: 1rem 1.5rem;
  background-color: $expense-selected-bg-color;
  align-items: center;

  // icon
  i.green-header-icon {
    flex-shrink: 0;

    // we want to have different kinds of icons, with pseudos and without
    &:not([class*='mm-icon-']),
    &[class*='mm-icon-']:before {
      width: 24px;
      background-color: $brand-color-green;
    }
  }

  // header with details
  .green-header-details {
    flex-grow: 1;
    flex-shrink: 0;

    display: flex;
    align-items: center;
    gap: 1rem;

    h1 {
      display: flex;

      margin: 0;
      &:empty {
        display: none;
      }

      font-size: 16px;
      font-weight: $f-light-bold;
      color: $brand-color-green;
    }
  }
}

label {
  padding: 0;
  margin: 0;

  display: block;
  color: $brand-color-green;

  &:has(> input[type='checkbox'], > input[type='radio']) {
    display: flex;
    align-items: flex-start;
    gap: 0.44rem;

    input {
      flex: 0 0 auto;
      transition: background-color 0.2s;
      float: none;
      vertical-align: initial;
    }
  }
}

.f-box {
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 10px;
  padding-top: 0;
  box-shadow: $three-sided-box-shadow;

  .section-header {
    margin: 0 -10px 5px;

    background-color: $secondary;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    display: flex;
    align-items: center;
    padding: 5px 8px;

    h4 {
      padding: 8px;
      margin: 0;
      font-weight: $f-normal;
      font-size: 14px;
      line-height: 1.5;
    }

    .select2-container--bootstrap {
      padding-bottom: 15px;
    }
  }

  .subheader {
    margin: 5px -10px 5px;
    padding: 5px 10px;

    background-color: $secondary;
    font-weight: $f-normal;
    font-size: inherit;

    cursor: default;
    color: $text-gray;

    &.has-toggle {
      cursor: pointer;

      &:after {
        content: '\25be'; // ▾
        margin: 0 0.25rem;
        transition: transform 0.2s;
        display: inline-block;
      }
      &[aria-expanded='true'] {
        &:after {
          transform: rotate(180deg);
        }
      }
    }

    &.first {
      margin: -10px;
      margin-bottom: 0;
      border-top: 0px !important;
    }
  }
}

.row.show {
  display: flex !important;
}

.settings-form {
  .f-box {
    background-color: white;
  }

  .settings-header {
    @include page-header;
    margin: 1.5rem 0 0.5rem;
  }
  .settings-header-descr {
    font-size: 13px;
  }
  .section-header .transaction-icon {
    width: 24px;
  }
}

.company-settings-tabs {
  margin: 1.5rem 0 1rem;

  .nav-link {
    border: none;
    border-bottom: 3px solid transparent;
    color: $text-gray;
    font-size: 15px;

    font-weight: $f-light-bold;

    padding-left: 1.5rem;
    padding-right: 1.5rem;

    &[aria-selected] {
      border-color: $brand-color-green;
    }
  }
}
.company-settings-tab-content {
  padding: 0 1rem;
}

.f-table-box {
  background: white;
  padding: 15px;
  margin: 0 0 10px 0;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  border-radius: 5px;

  table {
    width: 100%;
    // needed for sticky thead borders to stick as well
    border-collapse: separate;
    border-spacing: 0;

    thead {
      position: sticky;
      top: 0;
      z-index: 1;
    }

    th {
      padding: 6px 10px;
      font-size: 14px;
      font-weight: $f-light-bold;
      color: #444443;
      background-color: #edeff0;
      cursor: default;

      border: 1px solid #ddd;
      border-right: none;
      &:last-child {
        border-right: 1px solid #ddd;
      }
    }
    tr {
      cursor: default;

      &:last-child:not(:only-child) {
        td {
          border: 0;
        }
      }
      td {
        padding: 4px 10px;
        vertical-align: middle;
        border-bottom: 1px solid #d8d6d6;

        &:last-child {
          // TODO: clean this up, wtf, too wide a selector
          min-width: 260px;
          padding: 4px 8px;
        }
      }
    }
  }
}

@media (max-width: 420px) {
  .mobile-hide {
    display: none !important;
  }
}

.table {
  td,
  th {
    @media (max-width: 420px) {
      background: #f9f9f9 !important;
    }
  }
}

.table {
  th {
    font-weight: $f-light-bold;
  }
  td,
  th {
    border-color: #edeff0;
    padding: 10px;
    background-color: transparent;

    &.no-hor-padding {
      padding: 10px 0;
    }
  }
}

// TODO: `.f-box table *` styles need to be _abolised_
table.list-table {
  thead th {
    white-space: nowrap;
  }

  .empty-row td[colspan] {
    padding: 10px;
    text-align: center;
  }
  tr th,
  tr td {
    vertical-align: middle;
    padding: 6px 8px;
  }

  td {
    .badge {
      vertical-align: text-bottom;
      margin-left: 0.4rem;
    }
  }

  // cost centers columns
  &.cost-centers-table {
    tr th,
    tr td {
      @include text_overflow;

      // name
      // dimension
      &:nth-child(1),
      &:nth-child(2) {
        width: 25%;
        max-width: 0;
      }

      // resources
      &:nth-child(3) {
        width: 15%;
        max-width: 0;
      }

      // deactivated
      // required
      &:nth-child(4),
      &:nth-child(5) {
        width: 7.5%;
        max-width: 0;
        text-align: right;
      }

      // id
      &:nth-child(6) {
        text-align: right;
        max-width: calc(16px + 6ch); // 6 chars + paddings
      }

      // actions
      &:last-child {
        min-width: 200px;
      }
    }
  }

  // users columns
  &.users-table {
    tr th,
    tr td {
      @include text_overflow;

      // name
      &:nth-child(1) {
        width: 25%;
        max-width: 0;
      }

      // email
      &:nth-child(2) {
        width: 20%;
        max-width: 0;
      }

      // last sign in
      &:nth-child(3) {
        width: 10%;
        max-width: 0;
        min-width: 130px;
      }

      // roles
      &:nth-child(4) {
        width: 20%;
        max-width: 0;
      }

      // external_id, conditional col
      // meaning, we don't want its styles to 'spill' to the next col when
      // the col is not rendered
      &.external_id-conditional-col {
        text-align: right;
        max-width: calc(16px + 6ch); // 6 chars + paddings
      }

      // actions
      &:last-child {
        // "resend confirmation email" btn is loooong
        min-width: 280px;
      }
    }
  }

  // accounts columns
  &.accounts-table {
    tr th,
    tr td {
      @include text_overflow;

      // code
      &:nth-child(1) {
        width: 10%;
        max-width: 0;
      }

      // name
      &:nth-child(2) {
        width: 25%;
        max-width: 0;
      }

      // assignees (resources)
      &:nth-child(3) {
        width: 15%;
        max-width: 0;
      }

      // type
      &:nth-child(4) {
        width: 10%;
        max-width: 0;
        text-align: right;
      }

      // default_vat
      &:nth-child(5) {
        width: 10%;
        max-width: 0;
        text-align: right;
      }

      // deactivated
      &:nth-child(6) {
        width: 10%;
        max-width: 0;
        text-align: right;
      }

      // external_id, conditional col
      &.external_id-conditional-col {
        text-align: right;
        max-width: calc(16px + 12ch);
      }

      // actions
      &:last-child {
        min-width: 200px;
      }
    }
  }

  // reward types columns
  &.reward_types-table {
    tr th,
    tr td {
      @include text_overflow;

      // name
      &:nth-child(1) {
        width: 15%;
        max-width: 0;
      }
      // account_code
      &:nth-child(2) {
        text-align: right;
        max-width: calc(16px + 12ch);
      }
      // cost
      &:nth-child(3) {
        text-align: right;
        width: 10%;
        max-width: 0;
      }
      // fixed_price
      &:nth-child(4) {
        text-align: right;
        max-width: calc(16px + 10ch);
      }
      // tax_free
      &:nth-child(5) {
        text-align: right;
        max-width: calc(16px + 10ch);
      }
      // assignees
      &:nth-child(6) {
        width: 15%;
        max-width: 0;
      }
      // transaction_code, conditional column
      &.transaction_code-conditional-col {
        text-align: right;
        max-width: calc(16px + 10ch);
      }
      // deactivated
      &:nth-child(8) {
        text-align: right;
        max-width: calc(16px + 10ch);
      }
      // actions
      &:last-child {
        min-width: 200px;
      }
    }
  }

  // account rules columns
  &.account_rules-table {
    tr th,
    tr td {
      @include text_overflow;

      // pattern
      &:nth-child(1) {
        width: 35%;
        max-width: 0;
      }
      // account_code
      &:nth-child(2) {
        width: 35%;
        max-width: 0;
      }
      &:last-child {
        min-width: 200px;
      }
    }
  }

  // vendors columns
  &.vendors-table {
    tr th,
    tr td {
      @include text_overflow;

      // name
      &:nth-child(1) {
        width: 20%;
        max-width: 0;
      }
      // notes
      &:nth-child(2) {
        width: 35%;
        max-width: 0;
      }
      // deactivated
      &:nth-child(3) {
        text-align: right;
        max-width: calc(16px + 6ch);
      }
      // external_id
      &:nth-child(4) {
        text-align: right;
        max-width: calc(16px + 8ch);
      }
      // actions
      &:last-child {
        min-width: 200px;
      }
    }
  }

  // transaction rules columns
  &.transaction_rules-table {
    tr th,
    tr td {
      @include text_overflow;

      // name
      &:nth-child(1) {
        width: 8%;
        max-width: 0;
      }
      // if_email_contains
      &:nth-child(2) {
        width: 10%;
        max-width: 0;
      }
      // description
      &:nth-child(3) {
        width: 13.5%;
        max-width: 0;
      }
      // payment_method
      &:nth-child(4) {
        width: 13.5%;
        max-width: 0;
      }
      // purchase_type
      &:nth-child(5) {
        width: 13.5%;
        max-width: 0;
      }
      // cost_center
      &:nth-child(6) {
        width: 13.5%;
        max-width: 0;
      }
      // vat
      &:nth-child(7) {
        width: 8%;
        max-width: 0;
      }
      // user, conditional col
      &.username-conditional-col {
        width: 10%;
        max-width: 0;
      }
      // vat_code, conditional col
      &.vat_code-conditional-col {
        width: 8%;
        max-width: 0;
      }

      // actions
      &:last-child {
        min-width: 100px;
      }
    }
  }

  // subscriptions columns
  &.subscriptions-table {
    > thead > tr > th,
    > tbody > tr > td {
      @include text_overflow;

      // bill line name
      &:nth-child(1) {
        width: 50%;
        max-width: 0;
      }
      // created at
      &:nth-child(2) {
        width: 15%;
        text-align: right;
        max-width: 0;
      }

      // actions
      &:last-child {
        min-width: 100px;
      }
    }

    // receipts table container row
    tbody > tr.subtable > td {
      width: auto;
      padding: 0;
      padding-left: 15px;
    }
  }

  // subscriptions receipts subtable columns
  &.subscriptions-receipts-table {
    width: 100%;

    tr:last-child td {
      border-bottom: none;
    }

    tr th,
    tr td {
      @include text_overflow;

      // description
      &:nth-child(1) {
        width: 60%;
        max-width: 0;
      }
      // create at
      &:nth-child(2) {
        width: 20%;
        text-align: right;
        max-width: 0;
      }
      // total
      &:nth-child(3) {
        width: 20%;
        text-align: right;
        max-width: 0;
      }
    }
  }

  // credit cards columns
  &.credit_cards-table {
    tr th,
    tr td {
      @include text_overflow;

      // card_holder
      &:nth-child(1) {
        width: 20%;
        max-width: 0;
      }
      // card_number
      &:nth-child(2) {
        width: calc(16px + 20ch);
      }
      // asset_account
      &:nth-child(3) {
        width: 20%;
        max-width: 0;
      }
      // missing_receipts
      &:nth-child(4) {
        width: 15%;
        max-width: 0;
        text-align: right;
      }
      &:last-child {
        min-width: 0;
        max-width: 0;
      }
    }
  }
}

.approvers-list .approvers,
.credit-cards-list .credit-card,
.conditional-fields-list .conditional-field {
  display: flex;
  margin-bottom: 10px;
  align-items: flex-end;
  gap: 2%;
}

.approvers-list .approvers {
  .operator-col {
    width: 15%;
  }
  .approver-col {
    width: 35%;
    flex-grow: 1;
    max-width: 500px;
  }
  .limit-from-col,
  .limit-to-col {
    width: 15%;
  }
}

.credit-cards-list .credit-card {
  .users-col {
    width: 35%;
    max-width: 500px;
    flex-grow: 1;
  }
}
.conditional-fields-list .conditional-field {
  .input-type-col {
    min-width: 100px;
  }
  .values-col {
    flex-grow: 1;
    max-width: 500px;
  }
}

/* Policy */
.policy {
  h2,
  h4 {
    text-align: left;
    margin: 20px 0 20px 0;
    padding: 0;
  }

  h2 {
    margin-top: 0;
  }

  li {
    margin-left: 15px;
  }

  p,
  li {
    font-weight: $f-normal;
    font-size: 15px;
  }
}

.policy-settings {
  @media (max-width: 600px) {
    display: none;
  }
}
/* /Policy */

.list-view-layout {
  padding: 0 10px;
  padding-right: 5px;
}

#image-container > div {
  padding: 0 5px;
}

@import 'customer_ui/backgrid';
@import 'customer_ui/batches';
@import 'customer_ui/bills';
@import 'customer_ui/login';
@import 'customer_ui/expenses_list';
@import 'customer_ui/expenses_form';
@import 'customer_ui/expenses_summary';
@import 'customer_ui/dropzone';
@import 'customer_ui/preloader';
@import 'customer_ui/reports';
@import 'customer_ui/user_settings';
@import 'customer_ui/users_list';
@import 'customer_ui/integrations';
@import 'customer_ui/toggle_switch';
@import 'responsive'; // TODO: cosider removing this file

.error {
  color: #661304;
}

.select2-container--bootstrap {
  .select2-selection {
    border-top: none;
    box-shadow: none;
    border-right: none;
    border-left: none;
    border-radius: 0px;
  }
}

.select2-container--bootstrap .select2-selection--single .select2-selection__arrow b {
  border-color: #55b973 transparent transparent transparent;
}

.select2-results__option {
  font-family: Roboto, Arial, sans-serif;
  letter-spacing: 0;
  font-size: 14px;
  font-weight: $f-normal;
}

.select2-container--bootstrap.select2-container--disabled .select2-selection {
  border-color: #edeff0;
}

.select2-container--bootstrap .select2-results__option--highlighted[aria-selected] {
  background-color: #55b973;
}

.select2-container--bootstrap .select2-dropdown {
  border-color: #d3d2d3;
}

.select2-container--bootstrap.select2-container--focus .select2-selection,
.select2-container--bootstrap.select2-container--open .select2-selection {
  border: none;
  border-bottom: 1px solid #66afe9;
}

.select2-container--bootstrap .select2-search--dropdown .select2-search__field {
  border-radius: 0;
}

.select2-container--bootstrap .select2-selection--single .select2-selection__rendered {
  font-family: Roboto, Arial, sans-serif;
  font-weight: $f-normal;
  font-size: 14px;
  color: #000;
  border-color: #edeff0;
}

.section-body .header p {
  cursor: default;
  color: $brand-color-green;
}

.settings a {
  padding: 10px;
}

::-moz-selection {
  /* Code for Firefox */
  color: white;
  background: $brand-color-green;
}

::selection {
  color: white;
  background: $brand-color-green;
}

.simple-summary {
  background-color: #f9f9f9;
  padding: 10px 0px 1px 0px;
  border-top: 1px solid #edeff0;
  & p {
    text-align: left;
    font-weight: $f-normal;
    color: dimgray;
    cursor: default;
    padding-left: 10px;
  }
}

.select2-container--bootstrap .select2-selection--single {
  padding: 6px 24px 6px 0px;
}

.select2-container--bootstrap .select2-selection {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  background-color: transparent;
  color: #555555;
  border-bottom: 1px solid #edeff0;
}

.select2-search--dropdown {
  padding: 0px;
}

.select2-container--bootstrap
  .select2-selection--multiple
  .select2-search--inline
  .select2-search__field {
  padding: 0px;
}

.select2-container--bootstrap.select2-container--disabled .select2-selection {
  padding-left: 5px;
}

#select2-mileage_vehicle_id-container {
  padding-left: 5px !important;
}

#select2-currency-container {
  padding-left: 5px !important;
}

#select2-vat_percent-container {
  padding-left: 5px !important;
}

.form-container {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.form-content {
  padding: 10px;
  padding-bottom: 25px;
  background-color: white;
}

.bootbox {
  &.modal {
    // same as .select2-dropdown z-index, so we can have dropdowns in modals
    z-index: 1051;
  }
}

// fixes conflict between .flatpickr-day.hidden and BS .hidden
// which results in wrong day of week offset on multiple months calendar (showMonths > 1)
.flatpickr-calendar .flatpickr-day.hidden {
  display: unset !important;
  visibility: visible !important;
}
